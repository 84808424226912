/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import * as runtime from '../runtime';
// @ts-ignore
import type {
// @ts-ignore
  BulkSkuUpdateRequest,
// @ts-ignore
  Image,
// @ts-ignore
  Sku,
// @ts-ignore
  SkuCreateRequest,
// @ts-ignore
  SkuListResponse,
// @ts-ignore
  SkuUpdateRequest,
} from '../models/index';
// @ts-ignore
import {
// @ts-ignore
    BulkSkuUpdateRequestFromJSON,
// @ts-ignore
    BulkSkuUpdateRequestToJSON,
// @ts-ignore
    ImageFromJSON,
// @ts-ignore
    ImageToJSON,
// @ts-ignore
    SkuFromJSON,
// @ts-ignore
    SkuToJSON,
// @ts-ignore
    SkuCreateRequestFromJSON,
// @ts-ignore
    SkuCreateRequestToJSON,
// @ts-ignore
    SkuListResponseFromJSON,
// @ts-ignore
    SkuListResponseToJSON,
// @ts-ignore
    SkuUpdateRequestFromJSON,
// @ts-ignore
    SkuUpdateRequestToJSON,
} from '../models/index';

export interface BulkUpdateSkusRequest {
    bulkSkuUpdateRequest: BulkSkuUpdateRequest;
    supplierId?: string;
}

export interface CreateSkuRequest {
    skuCreateRequest: SkuCreateRequest;
}

export interface DeleteSkuRequest {
    id: string;
}

export interface GetSkuRequest {
    id: string;
    include?: Array<string>;
    languageCode?: string;
    currencyCode?: string;
}

export interface ListSkusRequest {
    query?: string;
    skuId?: Array<string>;
    storeId?: string;
    supplierId?: string;
    warehouseId?: string;
    promotions?: boolean;
    include?: Array<string>;
    limit?: number;
    offset?: number;
    languageCode?: string;
    currencyCode?: string;
    sortBy?: Array<string>;
    sortDirection?: Array<ListSkusSortDirectionEnum>;
}

export interface UpdateSkuRequest {
    id: string;
    skuUpdateRequest: SkuUpdateRequest;
}

export interface UploadSkuImageRequest {
    id: string;
    file?: Blob;
}

/**
 * 
 */
export class SKUsApi extends runtime.BaseAPI {

    /**
     * Update multiple SKUs in bulk
     */
    async bulkUpdateSkusRaw(requestParameters: BulkUpdateSkusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bulkSkuUpdateRequest === null || requestParameters.bulkSkuUpdateRequest === undefined) {
            throw new runtime.RequiredError('bulkSkuUpdateRequest','Required parameter requestParameters.bulkSkuUpdateRequest was null or undefined when calling bulkUpdateSkus.');
        }

        const queryParameters: any = {};

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplierId'] = requestParameters.supplierId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/skus/bulk`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BulkSkuUpdateRequestToJSON(requestParameters.bulkSkuUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update multiple SKUs in bulk
     */
    async bulkUpdateSkus(requestParameters: BulkUpdateSkusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdateSkusRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new SKU
     */
    async createSkuRaw(requestParameters: CreateSkuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Sku>> {
        if (requestParameters.skuCreateRequest === null || requestParameters.skuCreateRequest === undefined) {
            throw new runtime.RequiredError('skuCreateRequest','Required parameter requestParameters.skuCreateRequest was null or undefined when calling createSku.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/skus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SkuCreateRequestToJSON(requestParameters.skuCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuFromJSON(jsonValue));
    }

    /**
     * Create a new SKU
     */
    async createSku(requestParameters: CreateSkuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Sku> {
        const response = await this.createSkuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a SKU by ID
     */
    async deleteSkuRaw(requestParameters: DeleteSkuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSku.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/skus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a SKU by ID
     */
    async deleteSku(requestParameters: DeleteSkuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSkuRaw(requestParameters, initOverrides);
    }

    /**
     * Get details of a specific SKU
     */
    async getSkuRaw(requestParameters: GetSkuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Sku>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSku.');
        }

        const queryParameters: any = {};

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.languageCode !== undefined) {
            queryParameters['languageCode'] = requestParameters.languageCode;
        }

        if (requestParameters.currencyCode !== undefined) {
            queryParameters['currencyCode'] = requestParameters.currencyCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/skus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuFromJSON(jsonValue));
    }

    /**
     * Get details of a specific SKU
     */
    async getSku(requestParameters: GetSkuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Sku> {
        const response = await this.getSkuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of SKUs
     */
    async listSkusRaw(requestParameters: ListSkusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SkuListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.skuId) {
            queryParameters['skuId'] = requestParameters.skuId;
        }

        if (requestParameters.storeId !== undefined) {
            queryParameters['storeId'] = requestParameters.storeId;
        }

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplierId'] = requestParameters.supplierId;
        }

        if (requestParameters.warehouseId !== undefined) {
            queryParameters['warehouseId'] = requestParameters.warehouseId;
        }

        if (requestParameters.promotions !== undefined) {
            queryParameters['promotions'] = requestParameters.promotions;
        }

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.languageCode !== undefined) {
            queryParameters['languageCode'] = requestParameters.languageCode;
        }

        if (requestParameters.currencyCode !== undefined) {
            queryParameters['currencyCode'] = requestParameters.currencyCode;
        }

        if (requestParameters.sortBy) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.sortDirection) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/skus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuListResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of SKUs
     */
    async listSkus(requestParameters: ListSkusRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SkuListResponse> {
        const response = await this.listSkusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a SKU by ID
     */
    async updateSkuRaw(requestParameters: UpdateSkuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Sku>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSku.');
        }

        if (requestParameters.skuUpdateRequest === null || requestParameters.skuUpdateRequest === undefined) {
            throw new runtime.RequiredError('skuUpdateRequest','Required parameter requestParameters.skuUpdateRequest was null or undefined when calling updateSku.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/skus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SkuUpdateRequestToJSON(requestParameters.skuUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuFromJSON(jsonValue));
    }

    /**
     * Update a SKU by ID
     */
    async updateSku(requestParameters: UpdateSkuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Sku> {
        const response = await this.updateSkuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a SKU image
     */
    async uploadSkuImageRaw(requestParameters: UploadSkuImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Image>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadSkuImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/skus/{id}/images/upload`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageFromJSON(jsonValue));
    }

    /**
     * Upload a SKU image
     */
    async uploadSkuImage(requestParameters: UploadSkuImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Image> {
        const response = await this.uploadSkuImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListSkusSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListSkusSortDirectionEnum = typeof ListSkusSortDirectionEnum[keyof typeof ListSkusSortDirectionEnum];
