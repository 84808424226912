/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { SkuUpdateRequest } from './SkuUpdateRequest';
// @ts-ignore
import {
// @ts-ignore
    SkuUpdateRequestFromJSON,
// @ts-ignore
    SkuUpdateRequestFromJSONTyped,
// @ts-ignore
    SkuUpdateRequestToJSON,
} from './SkuUpdateRequest';

/**
 * 
 * @export
 * @interface BulkSkuUpdateRequest
 */
export interface BulkSkuUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof BulkSkuUpdateRequest
     */
    supplierId: string;
    /**
     * 
     * @type {Array<SkuUpdateRequest>}
     * @memberof BulkSkuUpdateRequest
     */
    skus: Array<SkuUpdateRequest>;
}

/**
 * Check if a given object implements the BulkSkuUpdateRequest interface.
 */
// @ts-ignore
export function instanceOfBulkSkuUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "skus" in value;

    return isInstance;
}

// @ts-ignore
export function BulkSkuUpdateRequestFromJSON(json: any): BulkSkuUpdateRequest {
    return BulkSkuUpdateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function BulkSkuUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkSkuUpdateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supplierId': json['supplier_id'],
        'skus': ((json['skus'] as Array<any>).map(SkuUpdateRequestFromJSON)),
    };
}

// @ts-ignore
export function BulkSkuUpdateRequestToJSON(value?: BulkSkuUpdateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supplier_id': value.supplierId,
        'skus': ((value.skus as Array<any>).map(SkuUpdateRequestToJSON)),
    };
}

