import {toImage} from '@/api/mappers/mapImage';
import {toPrice} from '@/api/mappers/mapPrice';
import {toApiProduct, toProduct} from '@/api/mappers/mapProduct';
import {toPromotion} from '@/api/mappers/mapPromotion';
import {toApiStatus, toStatus} from '@/api/mappers/mapStatus';
import {toSupplier} from '@/api/mappers/mapSupplier';
import {
    Dimensions as ApiDimensions,
    PriceCreate as ApiPriceCreate,
    PriceUpdate as ApiPriceUpdate,
    Weight as ApiWeight,
} from '@/gen/api';
import {Sku as ApiSku} from '@/gen/api/models/Sku';
import {SkuCreateRequest as ApiSkuCreateRequest} from '@/gen/api/models/SkuCreateRequest';
import {SkuListResponse as ApiSkuListResponse} from '@/gen/api/models/SkuListResponse';
import {SkuUpdateRequest as ApiSkuUpdateRequest} from '@/gen/api/models/SkuUpdateRequest';
import {Amount} from '@/models/Price';
import {Dimensions, SkuCreate, Sku as SkuModel, Skus, SkuUpdate, Weight} from '@/models/Sku';

export function toSku(apiSku: ApiSku): SkuModel {
    return {
        ...apiSku,
        externalCode: apiSku.externalCode ?? '',
        status: toStatus(apiSku.status),
        images: apiSku.images.map(toImage),
        product: apiSku.product && toProduct(apiSku.product),
        prices: apiSku.prices && apiSku.prices.map(toPrice),
        promotions: apiSku.promotions && apiSku.promotions.map(toPromotion),
        supplier: apiSku.supplier ? toSupplier(apiSku.supplier) : undefined,
    };
}

export function toSkus(response: ApiSkuListResponse): Skus {
    return {
        items: response.items.map(toSku),
        totalCount: response.totalCount,
        estimatedTotalCount: response.estimatedTotalCount,
    };
}

export function toApiSkuCreateRequest(sku: SkuCreate): ApiSkuCreateRequest {
    return {
        ...sku,
        moq: sku.moq ? parseInt(sku.moq.toString(), 10) : 0,
        quantity: sku.quantity ? parseInt(sku.quantity.toString(), 10) : 0,
        dimensions: sku.dimensions ? toApiDimensions(sku.dimensions) : undefined,
        weight: sku.weight ? toApiWeight(sku.weight) : undefined,
        status: sku.status ? toApiStatus(sku.status) : undefined,
        product: sku.product ? toApiProduct(sku.product) : undefined,
        price: sku.price ? toApiPriceCreate(sku.price) : undefined,
    };
}

function isEmpty(value: number | string): boolean {
    return value === null || value === undefined || value === 0 || value === '';
}

function toApiDimensions(dimensions: Dimensions): ApiDimensions | undefined {
    if (isEmpty(dimensions.length) ||
        isEmpty(dimensions.width) ||
        isEmpty(dimensions.height) ||
        isEmpty(dimensions.unit)
    ) {
        return;
    }

    return {
        length: dimensions.length,
        width: dimensions.width,
        height: dimensions.height,
        unit: dimensions.unit,
    };
}

function toApiWeight(weight: Weight): ApiWeight | undefined {
    if (isEmpty(weight.value) ||
        isEmpty(weight.unit)
    ) {
        return;
    }

    return {
        value: weight.value,
        unit: weight.unit,
    };
}

export function toApiSkuUpdateRequest(sku: SkuUpdate): ApiSkuUpdateRequest {
    return {
        ...sku,
        moq: sku.moq ? parseInt(sku.moq.toString(), 10) : 0,
        quantity: sku.quantity ? parseInt(sku.quantity.toString(), 10) : 0,
        dimensions: sku.dimensions ? toApiDimensions(sku.dimensions) : undefined,
        weight: sku.weight ? toApiWeight(sku.weight) : undefined,
        status: sku.status ? toApiStatus(sku.status) : undefined,
        product: sku.product ? toApiProduct(sku.product) : undefined,
        price: sku.price ? toApiPriceUpdate(sku.price) : undefined,
    };
}

function toApiPriceCreate(price: { price: Amount }): ApiPriceCreate {
    return {...price};
}

function toApiPriceUpdate(price: { price: Amount }): ApiPriceUpdate {
    return {...price};
}
