/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Dimensions } from './Dimensions';
// @ts-ignore
import {
// @ts-ignore
    DimensionsFromJSON,
// @ts-ignore
    DimensionsFromJSONTyped,
// @ts-ignore
    DimensionsToJSON,
} from './Dimensions';
// @ts-ignore
import type { Image } from './Image';
// @ts-ignore
import {
// @ts-ignore
    ImageFromJSON,
// @ts-ignore
    ImageFromJSONTyped,
// @ts-ignore
    ImageToJSON,
} from './Image';
// @ts-ignore
import type { PriceUpdate } from './PriceUpdate';
// @ts-ignore
import {
// @ts-ignore
    PriceUpdateFromJSON,
// @ts-ignore
    PriceUpdateFromJSONTyped,
// @ts-ignore
    PriceUpdateToJSON,
} from './PriceUpdate';
// @ts-ignore
import type { ProductUpdate } from './ProductUpdate';
// @ts-ignore
import {
// @ts-ignore
    ProductUpdateFromJSON,
// @ts-ignore
    ProductUpdateFromJSONTyped,
// @ts-ignore
    ProductUpdateToJSON,
} from './ProductUpdate';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { Weight } from './Weight';
// @ts-ignore
import {
// @ts-ignore
    WeightFromJSON,
// @ts-ignore
    WeightFromJSONTyped,
// @ts-ignore
    WeightToJSON,
} from './Weight';

/**
 * 
 * @export
 * @interface SkuUpdateRequest
 */
export interface SkuUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SkuUpdateRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SkuUpdateRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof SkuUpdateRequest
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof SkuUpdateRequest
     */
    supplierId: string;
    /**
     * 
     * @type {string}
     * @memberof SkuUpdateRequest
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SkuUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SkuUpdateRequest
     */
    description: string;
    /**
     * 
     * @type {Dimensions}
     * @memberof SkuUpdateRequest
     */
    dimensions?: Dimensions;
    /**
     * 
     * @type {Weight}
     * @memberof SkuUpdateRequest
     */
    weight?: Weight;
    /**
     * 
     * @type {number}
     * @memberof SkuUpdateRequest
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof SkuUpdateRequest
     */
    moq?: number;
    /**
     * 
     * @type {Array<Image>}
     * @memberof SkuUpdateRequest
     */
    images?: Array<Image>;
    /**
     * 
     * @type {Status}
     * @memberof SkuUpdateRequest
     */
    status?: Status;
    /**
     * 
     * @type {ProductUpdate}
     * @memberof SkuUpdateRequest
     */
    product?: ProductUpdate;
    /**
     * 
     * @type {PriceUpdate}
     * @memberof SkuUpdateRequest
     */
    price?: PriceUpdate;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SkuUpdateRequest
     */
    customData?: { [key: string]: string; };
}

/**
 * Check if a given object implements the SkuUpdateRequest interface.
 */
// @ts-ignore
export function instanceOfSkuUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

// @ts-ignore
export function SkuUpdateRequestFromJSON(json: any): SkuUpdateRequest {
    return SkuUpdateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function SkuUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuUpdateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'supplierId': json['supplier_id'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'name': json['name'],
        'description': json['description'],
        'dimensions': !exists(json, 'dimensions') ? undefined : DimensionsFromJSON(json['dimensions']),
        'weight': !exists(json, 'weight') ? undefined : WeightFromJSON(json['weight']),
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'moq': !exists(json, 'moq') ? undefined : json['moq'],
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ImageFromJSON)),
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'product': !exists(json, 'product') ? undefined : ProductUpdateFromJSON(json['product']),
        'price': !exists(json, 'price') ? undefined : PriceUpdateFromJSON(json['price']),
        'customData': !exists(json, 'custom_data') ? undefined : json['custom_data'],
    };
}

// @ts-ignore
export function SkuUpdateRequestToJSON(value?: SkuUpdateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'product_id': value.productId,
        'supplier_id': value.supplierId,
        'external_code': value.externalCode,
        'name': value.name,
        'description': value.description,
        'dimensions': DimensionsToJSON(value.dimensions),
        'weight': WeightToJSON(value.weight),
        'quantity': value.quantity,
        'moq': value.moq,
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ImageToJSON)),
        'status': StatusToJSON(value.status),
        'product': ProductUpdateToJSON(value.product),
        'price': PriceUpdateToJSON(value.price),
        'custom_data': value.customData,
    };
}

