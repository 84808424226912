import {toProductCategory} from '@/api/mappers/mapProductCategory';
import {toApiStatus} from '@/api/mappers/mapStatus';
import {Product as ApiProduct} from '@/gen/api';
import {Product} from '@/models/Product';

export function toProduct(apiProduct: ApiProduct): Product {
    return {
        ...apiProduct,
        productCategory: apiProduct.productCategory
            ? toProductCategory(apiProduct.productCategory)
            : undefined,
    };
}

export function toApiProduct(product: Product): ApiProduct {
    return {
        ...product,
        name: product.name ?? '',
        description: product.description ?? '',
        status: toApiStatus(product.status),
        productCategory: undefined,
    };
}
